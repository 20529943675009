import { FC, useEffect } from 'react';

import { useSignOut } from '../hooks';

const IndexPage: FC = () => {
  const { signOut } = useSignOut();

  useEffect(() => {
    signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

// eslint-disable-next-line import/no-default-export
export default IndexPage;
